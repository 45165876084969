import { Injectable } from '@angular/core';
import { Subject, Subscription, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventMediatorService {

  private subject$ = new Subject();

  on(event: any, action: any): Subscription {
    return this.subject$
      .pipe(
        filter((e: MediatorEvent) => e.name === event),
        map((e: MediatorEvent) => e.value)
      )
      .subscribe(action);
  }

  emit(event: MediatorEvent) {
    this.subject$.next(event);
  }
}

export class MediatorEvent {
  constructor(public sender: any, public name: any, public value?: any) { }
}

