/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../node_modules/@inflight/ui-ng/inflight-ui-ng.ngfactory";
import * as i2 from "@inflight/ui-ng";
import * as i3 from "@angular/router";
import * as i4 from "./app.component";
import * as i5 from "@inflight/theme-switcher-ng";
import * as i6 from "@ngx-translate/core";
import * as i7 from "@inflight/core-ng";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ui-loading", [], null, null, null, i1.View_LoadingComponent_0, i1.RenderType_LoadingComponent)), i0.ɵdid(1, 49152, null, 0, i2.LoadingComponent, [i2.LoadingService], { active: [0, "active"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "section", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(4, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 1, 0, currVal_0); _ck(_v, 4, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 49152, null, 0, i4.AppComponent, [i5.ThemeSwitcherService, i3.Router, i0.NgZone, i6.TranslateService, i7.SettingsService, i2.ModalService], null, null)], null, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i4.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
