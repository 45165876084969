import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
export class EventMediatorService {
    constructor() {
        this.subject$ = new Subject();
    }
    on(event, action) {
        return this.subject$
            .pipe(filter((e) => e.name === event), map((e) => e.value))
            .subscribe(action);
    }
    emit(event) {
        this.subject$.next(event);
    }
}
EventMediatorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventMediatorService_Factory() { return new EventMediatorService(); }, token: EventMediatorService, providedIn: "root" });
export class MediatorEvent {
    constructor(sender, name, value) {
        this.sender = sender;
        this.name = name;
        this.value = value;
    }
}
