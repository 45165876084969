import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from '@inflight/ui-ng';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('app/customer/portal/portal.module').then(m => m.PortalModule)
  },
  {
    path: 'error',
    component: ErrorComponent,
    data: { errorType: 'general' }
  },
  {
    path: 'error/page-not-found',
    component: ErrorComponent,
    data: { errorType: 'not-found' }
  },
  {
    path: 'error/network',
    component: ErrorComponent,
    data: { errorType: 'network' }
  },
  {
    path: '**',
    component: ErrorComponent,
    data: { errorType: 'not-found' }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
  private static _shouldReuseRoute(route: ActivatedRouteSnapshot): boolean {
    let next = route;
    while (next) {
      if (next.data && next.data.shouldReuseRoute === false) {
        return false;
      }

      next = next.firstChild;
    }
    return true;
  }

  private static _getComponentName(route: ActivatedRouteSnapshot): string {
    let next = route;
    while (next) {
      if (next.component && next.component['name']) {
        return next.component['name'];
      }

      next = next.firstChild;
    }
    return null;
  }

  static shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    current: ActivatedRouteSnapshot
  ): boolean {
    if (
      AppRoutingModule._getComponentName(future) ===
      AppRoutingModule._getComponentName(current)
    ) {
      if (
        AppRoutingModule._shouldReuseRoute(future) &&
        AppRoutingModule._shouldReuseRoute(current)
      ) {
        return true;
      }
    }
    return false;
  }
}
