import { ActivatedRouteSnapshot } from '@angular/router';

export class RouteReuseStrategy {
  private static shouldReuseRouteCheck(route: ActivatedRouteSnapshot): boolean {
    let next = route;
    let url = '';
    while (next) {
      if (next.data && next.data.shouldReuseRoute === false) {
        return false;
      }

      next = next.firstChild;
    }
    return true;
  }

  private static getComponentName(route: ActivatedRouteSnapshot): string {
    let next = route;
    let url = '';
    while (next) {
      if (next.component && next.component['name']) {
        return next.component['name'];
      }

      next = next.firstChild;
    }
    return null;
  }

  public static shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
    const result = RouteReuseStrategy.getComponentName(future) === RouteReuseStrategy.getComponentName(current)
                  && RouteReuseStrategy.shouldReuseRouteCheck(future)
                  && RouteReuseStrategy.shouldReuseRouteCheck(current)
                  && (future.routeConfig && current.routeConfig ? future.routeConfig.path === current.routeConfig.path : true);
    return result;
  }
}
