import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule } from 'app/app-routing.module';
import { Router } from '@angular/router';

import { UINgModule } from '@inflight/ui-ng';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { UICMSNgModule  } from '@inflight/ui-cms-ng';
import { RouteReuseStrategy } from 'app/routing/route-reuse-strategy';
import { EventMediatorService } from './core/event-mediator.service';
import { ThemeSwitcherNg } from '@inflight/theme-switcher-ng';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CoreNg } from '@inflight/core-ng';
import { GTMModule } from './gtm/gtm.module';


declare var window;

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CoreNg.forRoot(),
    UINgModule.forRoot(),
    UICMSNgModule.forRoot(),
    ThemeSwitcherNg.forRoot(),
    GTMModule.forRoot(),
    AppRoutingModule,
    HttpClientModule
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    EventMediatorService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(router: Router) {
    router.routeReuseStrategy.shouldReuseRoute = RouteReuseStrategy.shouldReuseRoute;
  }
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, window.i18nPrefix, window.i18nPostfix);
}
