import * as tslib_1 from "tslib";
import { GTMEvent } from './gtm-event.model';
import { GTMHelper, GoogleOptimizeService, SettingsService } from '@inflight/core-ng';
import { VirtualPageView } from './gtm-virtual-page-view.model';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
const GTM_USER_ID_KEY = '__gtmUserId__';
export class GTMService {
    constructor(router, optimize, title, settings) {
        this.optimize = optimize;
        this.title = title;
        this.settings = settings;
        this.isEnabled = false;
        if (sessionStorage[GTM_USER_ID_KEY]) {
            this.userId = sessionStorage[GTM_USER_ID_KEY];
        }
        this.isEnabled = !!this.settings.Properties.get('gtmConfigs').enabled;
        router.events
            .pipe(filter(event => !!(event instanceof NavigationEnd &&
            window.dataLayer &&
            event.urlAfterRedirects)), map((event) => {
            let url = event.urlAfterRedirects;
            const data = new VirtualPageView();
            data.virtualPageTitle = this.title.getTitle();
            const urlRegex = /([^\(?]+)[?\(]?.*/;
            const match = urlRegex.exec(event.urlAfterRedirects);
            if (match) {
                url = match[1];
            }
            if (url.lastIndexOf('/') === url.length - 1) {
                url = url.substr(0, url.length - 1);
            }
            return url;
        }), distinctUntilChanged())
            .subscribe((url) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const data = new VirtualPageView();
            data.virtualPageURL = url;
            this.push(data);
        }));
    }
    clear() {
        this.userId = undefined;
        delete sessionStorage[GTM_USER_ID_KEY];
    }
    login(userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.userId = yield GTMHelper.hash(userId);
            sessionStorage[GTM_USER_ID_KEY] = this.userId;
            const data = yield this.getGTMEvent();
            data.eventCategory = 'ibm';
            data.eventAction = 'login';
            data.eventLabel = 'completion';
            this.push(data);
        });
    }
    dashboardClick(tileText) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const data = yield this.getButtonClickEvent();
            data.eventCategory = 'ibm';
            data.eventAction = 'links-click';
            data.eventLabel = tileText;
            this.push(data);
        });
    }
    helpClick(linkText) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const data = yield this.getButtonClickEvent();
            data.eventCategory = 'ibm';
            data.eventAction = 'additional-links';
            data.eventLabel = linkText;
            this.push(data);
        });
    }
    loginClick() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const data = yield this.getButtonClickEvent();
            data.eventCategory = 'external landing page';
            data.eventAction = 'button-clicks';
            data.eventLabel = "login";
            this.push(data);
        });
    }
    newsClick(articleTitle) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const data = yield this.getButtonClickEvent();
            data.eventCategory = 'ibm';
            data.eventAction = 'news-article';
            data.eventLabel = articleTitle;
            this.push(data);
        });
    }
    quickLinkClick(linkName) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const data = yield this.getButtonClickEvent();
            data.eventCategory = 'ibm';
            data.eventAction = 'faculty-staff-links';
            data.eventLabel = linkName;
            this.push(data);
        });
    }
    helpfulLinkClick(linkName) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const data = yield this.getButtonClickEvent();
            data.eventCategory = 'external landing page';
            data.eventAction = 'button-clicks';
            data.eventLabel = linkName;
            this.push(data);
        });
    }
    personalInfoAction(componentName, action) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const data = yield this.getButtonClickEvent();
            data.eventCategory = 'ibm';
            data.eventAction = action;
            data.eventLabel = componentName;
            this.push(data);
        });
    }
    getGTMEvent() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const data = new GTMEvent();
            return data;
        });
    }
    getButtonClickEvent() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const data = yield this.getGTMEvent();
            data.eventAction = 'button-clicks';
            return data;
        });
    }
    push(data) {
        if (this.isEnabled) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(data);
            this.optimize.triggerOptimize();
        }
    }
}
