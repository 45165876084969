import { Component, NgZone } from '@angular/core';
import { ThemeSwitcherService, ITheme } from '@inflight/theme-switcher-ng';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '@inflight/core-ng'
import { ProxyUrlData, ModalService } from '@inflight/ui-ng';

const DEFAULT_ERROR_ROUTE = '/error';

@Component({
  selector: 'app-root',
  template: `<ui-loading [active]="loading"></ui-loading>
              <section>
                <router-outlet></router-outlet>
              </section>`
})
export class AppComponent {

  loading = false;
  showChatbot = false;
  modalView: 'proxy' | 'attachments' | 'sessionTimeout' | 'error';
  modalError: string = undefined;
  proxyUrlData: ProxyUrlData;

  constructor(
    private themesService: ThemeSwitcherService,
    private router: Router,
    private zone: NgZone,
    private translate: TranslateService,
    private settings: SettingsService,
    private modalService: ModalService,
  ) {
    this.setLanguage();
    this.subscribeToZoneError();
    this.subscribeToThemeService();
    this.loadThemes();
  }

  subscribeToZoneError() {
    this.zone.onError.subscribe(e => {
      this.router.navigateByUrl(DEFAULT_ERROR_ROUTE);
    });
  }

  setLanguage() {
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  subscribeToThemeService() {
    this.themesService.themeChange.subscribe(theme => {
      document.body.classList.remove('if-cloak');
      sessionStorage.setItem('theme', JSON.stringify(theme));
    });
  }

  hideModal(): void {
    this.modalView = undefined;
    this.modalService.hide();
  }


  loadThemes() {
    let theme = this.settings.Properties.get('theme') as ITheme | ITheme[];
    if (Array.isArray(theme)) {
      const themes = theme as ITheme[];
      this.themesService.loadThemes(themes);
      const sessionTheme = sessionStorage.getItem('theme') ? JSON.parse(sessionStorage.getItem('theme')) : undefined;
      themes.some(theme => {
        if ((sessionTheme && sessionTheme.name === theme.name) || theme.isActive) {
          this.themesService.activateTheme(theme);
          return true;
        }
        return false;
      });
    } else {
      this.themesService.activateTheme(theme as ITheme);
    }
  }
}
