import { Injectable } from '@angular/core';
import { GTMEvent } from './gtm-event.model';
import { GTMHelper, GoogleOptimizeService, SettingsService } from '@inflight/core-ng';
import { VirtualPageView } from './gtm-virtual-page-view.model';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

declare var window;

const GTM_USER_ID_KEY = '__gtmUserId__';

@Injectable()
export class GTMService {
  private userId: string;
  private isEnabled = false;

  constructor(
    router: Router,
    private optimize: GoogleOptimizeService,
    private title: Title,
    private settings: SettingsService
  ) {
    if(sessionStorage[GTM_USER_ID_KEY]){
      this.userId = sessionStorage[GTM_USER_ID_KEY];
    }

    this.isEnabled = !!this.settings.Properties.get('gtmConfigs').enabled;

    router.events
      .pipe(
        filter(
          event =>
            !!(
              event instanceof NavigationEnd &&
              window.dataLayer &&
              event.urlAfterRedirects
            )
        ),
        map((event: NavigationEnd): string => {
          let url = event.urlAfterRedirects;

          const data = new VirtualPageView();
          data.virtualPageTitle = this.title.getTitle();

          const urlRegex = /([^\(?]+)[?\(]?.*/;

          const match = urlRegex.exec(event.urlAfterRedirects);
          if (match) {
            url = match[1];
          }

          if (url.lastIndexOf('/') === url.length - 1) {
            url = url.substr(0, url.length - 1);
          }

          return url;
        }),
        distinctUntilChanged()
      )
      .subscribe(async (url: string) => {
        const data = new VirtualPageView();

        data.virtualPageURL = url;

        this.push(data);
      });
  }

  clear() {
    this.userId = undefined;
    delete sessionStorage[GTM_USER_ID_KEY];
  }

  async login(userId: string) {
    this.userId = await GTMHelper.hash(userId);
    sessionStorage[GTM_USER_ID_KEY] = this.userId;
    const data = await this.getGTMEvent();
    data.eventCategory = 'ibm';
    data.eventAction = 'login';
    data.eventLabel = 'completion';
    this.push(data);
  }

  async dashboardClick(tileText: string) {
    const data = await this.getButtonClickEvent();
    data.eventCategory = 'ibm';
    data.eventAction = 'links-click';
    data.eventLabel = tileText;
    this.push(data);
  }

  async helpClick(linkText: string) {
    const data = await this.getButtonClickEvent();
    data.eventCategory = 'ibm';
    data.eventAction = 'additional-links';
    data.eventLabel = linkText;
    this.push(data);
  }

  public async loginClick() {
    const data = await this.getButtonClickEvent();
    data.eventCategory = 'external landing page';
    data.eventAction = 'button-clicks';
    data.eventLabel = "login";
    this.push(data);
  }

  async newsClick(articleTitle: string) {
    const data = await this.getButtonClickEvent();
    data.eventCategory = 'ibm';
    data.eventAction = 'news-article';
    data.eventLabel = articleTitle;
    this.push(data);
  }

  async quickLinkClick(linkName: string) {
    const data = await this.getButtonClickEvent();
    data.eventCategory = 'ibm';
    data.eventAction = 'faculty-staff-links';
    data.eventLabel = linkName;
    this.push(data);
  }

  async helpfulLinkClick(linkName: string) {
    const data = await this.getButtonClickEvent();
    data.eventCategory = 'external landing page';
    data.eventAction = 'button-clicks';
    data.eventLabel = linkName;
    this.push(data);
  }

  async personalInfoAction(
    componentName: string,
    action: 'add' | 'edit' | 'delete' | 'save'
  ) {
    const data = await this.getButtonClickEvent();
    data.eventCategory = 'ibm';
    data.eventAction = action;
    data.eventLabel = componentName;
    this.push(data);
  }

  private async getGTMEvent(): Promise<GTMEvent> {
    const data = new GTMEvent();
    return data;
  }

  private async getButtonClickEvent(): Promise<GTMEvent> {
    const data = await this.getGTMEvent();
    data.eventAction = 'button-clicks';
    return data;
  }

  private push(data: GTMEvent | VirtualPageView) {
    if(this.isEnabled) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(data);
      this.optimize.triggerOptimize();
    }
  }
}
