import { NgModule, ModuleWithProviders } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { GTMService } from './gtm.service';
import {
  GTMDashboardClickDirective,
  GTMHelpClickDirective,
  GTMNewsClickDirective,
  GTMQuickLinkClickDirective,
  GTMPersonalInfoActionDirective,
  GTMLoginClickDirective,
  GTHelpfulLinkClickDirective
} from './gtm.directive';

@NgModule({
  imports: [
    ReactiveFormsModule,
  ],
  exports: [
    GTMDashboardClickDirective,
    GTMHelpClickDirective,
    GTMNewsClickDirective,
    GTMQuickLinkClickDirective,
    GTMPersonalInfoActionDirective,
    GTMLoginClickDirective,
    GTHelpfulLinkClickDirective
  ],
  declarations: [
    GTMDashboardClickDirective,
    GTMHelpClickDirective,
    GTMNewsClickDirective,
    GTMQuickLinkClickDirective,
    GTMPersonalInfoActionDirective,
    GTMLoginClickDirective,
    GTHelpfulLinkClickDirective
  ]
})
export class GTMModule {
  static forRoot(): ModuleWithProviders<GTMModule> {
    return {
      ngModule: GTMModule,
      providers: [GTMService]
    };
  }
}
